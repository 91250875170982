import ErrorComponent from 'next/error';
import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function NotFound() {
  const router = useRouter();

  // This is some logging to capture frequency of 404 errors.
  useEffect(() => {
    Sentry.captureException(
      new Error(`Requested URL not found. URL requested: ${router.asPath}`)
    );
  });

  return (
    <>
      <ErrorComponent statusCode={404} />
    </>
  );
}
